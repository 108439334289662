import { fontFamilies, fontWeights, typeScale } from './typography';

export const fonts = {
    canvasTitleSmall: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.medium};
        font-weight: ${fontWeights.bold};
        text-align: center;
    `
};
