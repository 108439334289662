import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { min } from 'ramda';
import { ItemWrapper } from './ItemWrapper';
import { ItemHeader } from './ItemHeader';
import { ItemThumbnail } from './ItemThumbnail';
import { ItemThumbnailWrapper } from './ItemThumbnailWrapper';

export const Item = ({
    name,
    thumbnailUrl,
    onClick,
    wrapperSize
}) => {
    const [thumbnailShrink, setThumbnailShrink] = useState(min(window.innerWidth / 650, 1));

    useEffect(() => {
        setThumbnailShrink(min(window.innerWidth / 650, 1));
    }, [window.innerWidth]);

    return (
        <ItemWrapper
            numberOfItems={3}
            onClick={onClick}
            wrapperSize={wrapperSize}
            thumbnailShrink={thumbnailShrink}
        >
            <ItemHeader>
                {name}
            </ItemHeader>
            <ItemThumbnailWrapper>
                <ItemThumbnail src={thumbnailUrl} alt={`Create ${name}`} />
            </ItemThumbnailWrapper>
        </ItemWrapper>
    );
};
Item.propTypes = {
    name: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    wrapperSize: PropTypes.any.isRequired
};

Item.defaultProps = {
};
