import React from 'react';
import PropTypes from 'prop-types';
import { identity, map } from 'ramda';

import { FlexRowWrapper } from '../../../../components/layout/FlexRowWrapper';
import { Item } from './Item';

export const TemplateListItem = ({
    listItems,
    onClick
}) => (
    <FlexRowWrapper
        wrap="wrap"
        fullHeight
        fullWidth
        overflowY="scroll"
        justify="center"
    >
        {map((listItem) => (
            <Item
                wrapperSize={800}
                key={listItem.name}
                name={listItem.name}
                thumbnailUrl={listItem.thumbnailUrl}
                onClick={() => onClick(listItem.filename)}
            />
        ), listItems)}
    </FlexRowWrapper>
);

/*eslint-disable */
TemplateListItem.propTypes = {
    listItems: PropTypes.array,
    onClick: PropTypes.func,
    width: PropTypes.number,
    outerHeight: PropTypes.number
};

TemplateListItem.defaultProps = {
    listItems: [],
    onClick: identity,
    width: null,
    height: null
};
