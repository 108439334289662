export const CANVAS_MODELS = [
    {
        title: 'Basic Kanban Canvas',
        filename: 'kanban-canvas',
        id: 4
    },
    {
        title: 'Business Model Canvas',
        filename: 'business-model-canvas',
        id: 1
    },
    {
        title: 'Business Model Zen Canvas',
        filename: 'business-model-zen-canvas',
        id: 22
    },
    {
        title: 'Canvas4Change',
        filename: 'canvas-4-change',
        id: 20
    },
    {
        title: 'Contract Canvas',
        filename: 'contract-canvas',
        id: 16
    },
    {
        title: 'Customer Journey Canvas',
        filename: 'customer-journey-canvas',
        id: 3
    },
    {
        title: 'Disruption by Design Canvas',
        filename: 'disruption-by-design-canvas',
        id: 36
    },
    {
        title: 'Feedback Canvas',
        filename: 'feedback-canvas',
        id: 6
    },
    {
        title: 'HR Innovation Canvas',
        filename: 'hr-innovation-canvas',
        id: 23
    },
    {
        title: 'Lean Canvas',
        filename: 'lean-canvas',
        id: 14
    },
    {
        title: 'Lean Change Canvas',
        filename: 'lean-change-canvas',
        id: 17
    },
    {
        title: 'Pitch Planner Canvas',
        filename: 'pitch-planner-canvas',
        id: 24
    },
    {
        title: 'Project Canvas',
        filename: 'project-canvas',
        id: 15
    },
    {
        title: 'SWOT Analysis',
        filename: 'swot-canvas',
        id: 2
    },
    {
        title: 'Whiteboard Canvas',
        filename: 'whiteboard-canvas',
        id: 37
    },
    {
        title: "Roman's Product Canvas",
        filename: 'romans-product-canvas',
        id: 38
    },
    {
        title: 'Roman Pichler',
        filename: 'roman-pichler',
        id: 39
    },
    {
        title: 'Go Product Roadmap Canvas',
        filename: 'go-product-roadmap-canvas',
        id: 40
    },
    {
        title: 'Gamification Model Canvas',
        filename: 'gamification-model-canvas',
        id: 41
    },
    {
        title: 'Product Market Fit Canvas',
        filename: 'product-market-fit-canvas',
        id: 42
    },
    {
        title: 'Social',
        filename: 'social',
        id: 43
    },
    {
        title: 'User Centered Design Canvas',
        filename: 'user-centered-design-canvas',
        id: 44
    },
    {
        title: 'Internet Presence',
        filename: 'internet-presence',
        id: 45
    },
    {
        title: 'Requirements Discovery Canvas',
        filename: 'requirements-discovery-canvas',
        id: 46
    },
    {
        title: 'Sprint Goal',
        filename: 'sprint-goal',
        id: 47
    },
    {
        title: 'Product Vision Board Extended',
        filename: 'product-vision-board-extended-canvas',
        id: 48
    },
    {
        title: '4L retrospective canvas',
        filename: '4l-retrospective-canvas',
        id: 49
    },
    {
        title: 'Persona Canvas',
        filename: 'persona-canvas',
        id: 50
    },
    {
        title: '4 Quarters',
        filename: '4-quarters-canvas',
        id: 51
    },
    {
        title: 'Twelve months',
        filename: 'twelve-months-canvas',
        id: 52
    },
    {
        title: 'Mission Model Canvas',
        filename: 'mission-model-canvas',
        id: 53
    },
    {
        title: 'Strategy Canvas',
        filename: 'strategy-canvas',
        id: 54
    },
    {
        title: 'Change Model Canvas',
        filename: 'change-model-canvas',
        id: 55
    },
    {
        title: 'lernOS Canvas',
        filename: 'lernos-canvas',
        id: 56
    },
    {
        title: 'Digital Marketing Canvas',
        filename: 'digital-marketing-canvas',
        id: 57
    },
    {
        title: 'Analytics Maturity Canvas',
        filename: 'analytics-maturity-canvas',
        id: 58
    },
    {
        title: 'Social Lean Canvas',
        filename: 'social-lean-canvas',
        id: 59
    },
    {
        title: 'Team Canvas',
        filename: 'team-canvas',
        id: 60
    },
    {
        title: 'Personal Business Model Canvas',
        filename: 'personal-business-model-canvas',
        id: 61
    },
    {
        title: 'Top 3 Priorities Canvas',
        filename: 'top-3-priorities-canvas',
        id: 62
    },
    {
        title: '3x3 Matrix Canvas',
        filename: '3x3-matrix-canvas',
        id: 63
    },
    {
        title: 'Pros & Cons Canvas',
        filename: 'pros-and-cons-canvas',
        id: 64
    },
    {
        title: '2 Segments Canvas',
        filename: '2-segments-canvas',
        id: 65
    },
    {
        title: '5 Columns Canvas',
        filename: '5-columns-canvas',
        id: 66
    },
    {
        title: 'Internal Communication Model Canvas',
        filename: 'internal-communication-model-canvas',
        id: 67
    },
    {
        title: 'Platform Design Canvas',
        filename: 'platform-design-canvas',
        id: 68
    },
    {
        title: 'Personal Service Model Canvas',
        filename: 'personal-service-model-canvas',
        id: 69
    },
    {
        title: 'Team Service Model Canvas',
        filename: 'team-service-model-canvas',
        id: 70
    },
    {
        title: 'Meeting Faciliator Canvas',
        filename: 'meeting-facilitator-canvas',
        id: 71
    },
    {
        title: 'EBG Product Canvas 1/2',
        filename: 'ebg-product-canvas-1',
        id: 72
    },
    {
        title: 'EBG Product Canvas 2/2',
        filename: 'ebg-product-canvas-2',
        id: 73
    },
    {
        title: 'Empathy Map Canvas',
        filename: 'empathy-map-canvas',
        id: 74
    },
    {
        title: 'Decentralized Business Model Canvas',
        filename: 'decentralized-business-model-canvas',
        id: 75
    },
    {
        title: 'MatchUp Canvas',
        filename: 'matchup-canvas',
        id: 76
    },
    {
        title: 'Growth Hacking Canvas',
        filename: 'growth-hacking-canvas',
        id: 77
    },
    {
        title: 'CV / Resume Canvas',
        filename: 'cv-resume-canvas',
        id: 78
    },
    {
        title: 'Data Strategy Canvas',
        filename: 'data-strategy-canvas',
        id: 79
    },
    {
        title: 'Project Canvas Over The Fence',
        filename: 'project-canvas-over-the-fence',
        id: 80
    },
    {
        title: 'MVP Experiment Canvas',
        filename: 'mvp-experiment-canvas',
        id: 81
    },
    {
        title: 'Software Testing Canvas',
        filename: 'software-testing-canvas',
        id: 82
    },
    {
        title: 'Content Strategy Canvas',
        filename: 'content-strategy-canvas',
        id: 83
    },
    {
        title: 'Future of Work Canvas',
        filename: 'future-of-work-canvas',
        id: 84
    },
    {
        title: 'Lean Procurement Canvas',
        filename: 'lean-procurement-canvas',
        id: 85
    },
    {
        title: 'Organization Canvas',
        filename: 'organization-canvas',
        id: 86
    },
    {
        title: 'Q&A Horizontal Canvas',
        filename: 'qa-horizontal-canvas',
        id: 94
    },
    {
        title: 'Q&A Vertical Canvas',
        filename: 'qa-vertical-canvas',
        id: 95
    },
    {
        title: 'Mission Canvas',
        filename: 'mission-canvas',
        id: 96
    },
    {
        title: 'Process Model Canvas',
        filename: 'process-model-canvas',
        id: 97
    },
    {
        title: 'Wide Business Model Canvas',
        filename: 'wide-business-model-canvas',
        id: 98
    },
    {
        title: 'Wide Business Model Canvas 1',
        filename: 'wide-business-model-canvas-1',
        id: 99
    },
    {
        title: 'Wide Business Model Canvas 2',
        filename: 'wide-business-model-canvas-2',
        id: 100
    },
    {
        title: 'Wide Business Model Canvas 3',
        filename: 'wide-business-model-canvas-3',
        id: 101
    },
    {
        title: 'Architecture Inception Canvas',
        filename: 'architecture-inception-canvas',
        id: 102
    },
    {
        title: 'Architecture Communication Canvas',
        filename: 'architecture-communication-canvas',
        id: 103
    }
];
