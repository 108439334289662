import styled from 'styled-components';
import { prop, theme } from 'styled-tools';

export const ItemWrapper = styled.div`
    width: min((${prop('wrapperSize')}px - 2 * (${prop('numberOfItems')} + 1) * ${theme('dims.spacings.small')}) / ${prop('numberOfItems')}, 40vw);
    position: relative;
    margin: ${theme('dims.spacings.small')};
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    
    height: min(((${prop('wrapperSize')}px - 2 * (${prop('numberOfItems')} + 1) * ${theme('dims.spacings.small')}) / ${prop('numberOfItems')}) * 4 / 5, 4 / 5 * 40vw);
    max-height: calc(((${prop('wrapperSize')}px - 2 * (${prop('numberOfItems')} + 1) * ${theme('dims.spacings.small')}) / ${prop('numberOfItems')}) * 4 / 5);

`;
